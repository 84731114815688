<template>
    <router-view></router-view>
</template>
<script>
    import facilityStoreModule from './facilityStoreModule.js';
    import store from '@/store';
    import { onUnmounted } from '@vue/composition-api';

    export default {
        setup() {
            const FACILITY_APP_STORE_MODULE_NAME = 'app-facility';

            // Register module
            if (!store.hasModule(FACILITY_APP_STORE_MODULE_NAME))
                store.registerModule(FACILITY_APP_STORE_MODULE_NAME, facilityStoreModule);

            // UnRegister on leave
            onUnmounted(() => {
                if (store.hasModule(FACILITY_APP_STORE_MODULE_NAME))
                    store.unregisterModule(FACILITY_APP_STORE_MODULE_NAME);
            });
        },
    };
</script>
